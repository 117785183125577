@font-face {
font-family: '__tiemposHeadline_912bd2';
src: url(/_next/static/media/a38d1e86292212ef-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__tiemposHeadline_912bd2';
src: url(/_next/static/media/ad3d6f1d0b0df35e-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: '__tiemposHeadline_Fallback_912bd2';src: local("Arial");ascent-override: 78.92%;descent-override: 21.87%;line-gap-override: 20.16%;size-adjust: 99.22%
}.__className_912bd2 {font-family: '__tiemposHeadline_912bd2', '__tiemposHeadline_Fallback_912bd2', Times New Roman, Times, serif
}

